$lightGray: #848895;

.solFooter {
  font-size: 1rem;
  margin-top: 5rem;
  padding: 5rem 0;
  background-color: #000508;
  color: #fff;
  background-image: radial-gradient(
    farthest-corner at bottom right,
    #3a233f,
    transparent 250px
  );

  border: 1px solid #141414;
  border-radius: 12px 12px 0 0;

  @media (min-width: 768px) {
    background-image: radial-gradient(
        farthest-side at bottom left,
        #271d3b,
        transparent 900px
      ),
      radial-gradient(
        farthest-corner at bottom right,
        #3a233f,
        transparent 1000px
      );
  }

  :global {
    a:not(.dropdown-item),
    button {
      color: $lightGray;

      &:hover {
        color: #fff;
      }
    }
  }

  ul a {
    padding: 6px 0;
    display: inline-block;
  }

  // Managed by
  &__foundation {
    font-family: "DSemi";
    margin-bottom: 0;
    line-height: 1;
  }

  &__foundation-logo {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  // Social icons
  &__social {
    margin-bottom: 20px;

    a {
      background: $lightGray;
      display: inline-flex;
      padding: 5px;
      border-radius: 50%;

      &:hover {
        background: #fff;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      svg {
        fill: #111;
      }
    }
  }

  // Copyright
  &__copyright {
    color: $lightGray;
  }
}
