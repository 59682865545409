.developers-nav {
  position: relative;
  z-index: 1;
  font-size: 0.85em;
  background-color: rgb(18 18 18 / 95%);
  transition: background-color 0.3s ease;
  color: var(--body-text-secondary);
  border-color: rgba(255, 255, 255, 0.05);
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;

  nav {
    a {
      color: var(--body-text-secondary);
      display: inline-block;
      border-radius: 6px;
      padding: 2px 10px;
      border: 1px solid transparent;

      &:hover,
      .active {
        color: var(--body-text) !important;
      }

      &:not(:last-of-type) {
        margin-right: 5px;
      }

      &:global(.active) {
        background-color: rgba(204, 204, 204, 0.1);
        border-color: rgba(255, 255, 255, 0.1);
        color: var(--body-text) !important;

        &:hover {
          border-color: rgba(255, 255, 255, 0.2) !important;
        }
      }
    }
  }
}

:global(.light) {
  .developers-nav {
    background-color: rgb(255 255 255 / 95%);
    border-color: rgba(0, 0, 0, 0.05);

    nav {
      a {
        &:global(.active) {
          background-color: rgb(204, 204, 204, 0.35);
          border-color: rgba(0, 0, 0, 0.1);

          &:hover {
            border-color: rgba(0, 0, 0, 0.3) !important;
          }
        }
      }
    }
  }
}
