.header {
  &__toggle {
    display: flex;
    border: none;
    margin-left: 15px;
    transition:
      transform 0.3s ease,
      rotate 0.3s ease;

    &:hover {
      transform: scale(1.1);
      rotate: 15deg;

      svg {
        fill: var(--body-text);
      }
    }
  }
}
